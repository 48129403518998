import { template as template_3f99351bc0e54f70ba9f29282c25156c } from "@ember/template-compiler";
const WelcomeHeader = template_3f99351bc0e54f70ba9f29282c25156c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
