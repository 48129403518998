import { template as template_b6c7a6e4ad134c70a7102759299f911d } from "@ember/template-compiler";
const FKControlMenuContainer = template_b6c7a6e4ad134c70a7102759299f911d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
